<template>
  <main class="upload">
    <label>Was wünschst du dir an diesen Ort?</label>
    <div
      class="desire"
      :style="`background-image: url(${lastimg});`"
      :key="$store.state.picture"
    >
      <textarea
        placeholder="Schreibe Deine Idee..."
        v-model="desire"
      ></textarea>
    </div>
    <div v-if="uploading" class="center-spinner">
      <div class="spinner"></div>
    </div>
    <teleport to="#buttongroupfooter" v-if="!uploading">
      <button v-if="!waiting_for_location" @click="upload" class="orange bigbutton">Upload</button>
      <p class="collecting-pos" v-else>Position wird ermittelt...</p>
    </teleport>
  </main>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { uploadData, baseURL } from "../api";
import { useRouter } from "vue-router";
export default {
  name: "upload",
  components: {},
  setup() {
    const desire = ref("");
    const uploading = ref(false);
    const store = useStore();
    const router = useRouter();
    const lastimg = ref("");

    const waiting_for_location = ref(true)

    let location = undefined;
    setpicture(store.state.picture)
    watch(
      computed(() => store.state.picture),
      setpicture
    );
    function setpicture(picture) {
        if (typeof picture !== "undefined") {
          lastimg.value = URL.createObjectURL(picture);
        } else {
          lastimg.value = "";
        }
    }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((loc) => {
        location = loc.coords.latitude + "," + loc.coords.longitude;
        console.log(location);
        waiting_for_location.value = false
      },(e) => {
        waiting_for_location.value = false
      });
    } else {
      waiting_for_location.value = false
    }

    async function upload() {
      if (typeof store.state.picture === "undefined")
        router.push({ name: "start", params: { qr: "none" } });
      if (store.state.location == "") {
        store.dispatch("firenotice", {
          notice: "Kein Qr code angegeben",
          notice_type: "alert-danger",
        });
        return;
      }

      let form = new FormData();
      form.append("location", store.state.location);
      form.append("desire", desire.value);
      form.append("img", store.state.picture, "upload.jpg");
      if (typeof location !== "undefined") form.append("geolocation", location);

      uploading.value = true;
      let res = await uploadData(
        baseURL + "api/img/upload",
        form,
        store.state.token
      );
      uploading.value = false;
      if (res.status == "ok") {
        store.commit("settakenimages", res.takenimages);
        store.commit("uploaded", {
          qr_code_fullnames: res.qr_code_fullnames,
          lastupload: res.filename,
        });
        router.push({ name: "overview" });
      } else {
        store.dispatch("firenotice", {
          notice: res.message,
          notice_type: "alert-danger",
        });
      }
    }

    return {
      desire,
      uploading,
      upload,
      lastimg,
      waiting_for_location
    };
  },
};
</script>
